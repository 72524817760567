@font-face {
  font-family: Lato;
  src: url("../public/fonts/lato/Lato-Light.ttf") format("opentype");
}

@font-face {
  font-family: Lato;
  font-weight: bold;
  src: url("../public/fonts/lato/Lato-Bold.ttf") format("opentype");
}

@font-face {
  font-family: LatoRegular;
  font-weight: bold;
  src: url("../public/fonts/lato/Lato-Regular.ttf") format("opentype");
}

body {
  font-family: Lato, sans-serif;
  font-weight: normal;
  color: #000000;
  background: #f6f6f6;
}

.h5, h5 {
  font-weight: bold;
  font-size: 1.2rem;
}
.nav-top-link {
  padding: 15px 15px !important;
  margin-top: -12px;
  margin-bottom: -12px;
}

.nav-link {
  font-weight: 400;
}

.nav-link > span {
  padding-left: 10px;
}

.navbar-toggler {
  right: 10px;
  position: absolute;
}

.navbar-mobile {
  z-index: 999;
}

.navbar-brand {
  text-decoration: none;
}

.navbar-brand > img{
  height: 40px;
  padding-left: 10px;
}

.navbar-top {
  background-color: #ffffff !important;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}

.nav-bar-top-breadcrumbs {
  background-color: #009FE3;
  position: fixed;
  top: 66px;
  left: 50px;
  right: 0px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  height: 40px;
  z-index: 41;
}

@media (max-width: 767px) {
  .nav-bar-top-breadcrumbs {
    position: absolute;
    left: 0px;
  }
}

.dropdown-toggle::after {
  top: 20px !important;
}

.container-fluid {
  max-width: 1700px;
  margin-top: 60px;
}

@media (min-width: 768px) {
  .container-fluid {
    padding-top: 25px;
    padding-left: 265px;
  }
}

.container-content {
  padding: 40px 45px 0px 45px;
}

@media (max-width: 767px) {
  .container-content {
    padding: 0px 25px 0px 25px;
  }
}

.container-body {
  position: relative;
  margin: 20px -20px 20px -20px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
  border-radius: 5px;
}

.container-center-small {
  position: absolute;
  max-width: 400px;
  top: 100px;
  left: 50%;
  transform: translate(-50%);
  padding: 25px 15px;
}

.container-full {
  padding-top: 0px;
  min-width: fit-content;
}

.container-no-padding-bottom {
  padding-bottom: 0px;
}

.card {
  border-radius: 0;
}

.row-header {
  text-transform: uppercase;
  margin-top: 10px;
  padding-left: 0px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dee2e6;
}

.table .thead-light th {
  font-weight: 400;
}

.row-no-top-border-and-padding td {
  padding-top: 0px;
  border-top: 0px;
}

.row-no-top-border td {
  border-top: 0px;
}

.table-transparent-top-border td {
  border-top: 1px solid transparent;
}

.table-details {
  margin-bottom: 0rem;
}

.table-div {
  overflow-x: auto;
}

.cell-bottom-border {
  border-bottom: 1px solid #dee2e6;
}

.btn-link {
  padding-bottom: 0px;
  padding-top: 0px;
}

.row {
  padding-top: 7px;
  padding-bottom: 7px;
}

.no-content {
  padding-left: 25px;
}

body.busy-cursor {
  cursor: progress;
}

.pagination-nav {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #dee2e6;
}

.pagination {
  display:inline;
}

.page-item {
  display:inline-block;
}

.page-link {
  border: none;
}

.pages {
  padding-left: 10px;
  padding-right: 10px;
}

.page-item:hover > .page-link {
  background-color: #009FE3;
  color: #ffffff !important;
}

.page-item.disabled > .page-link {
  background-color: transparent;
  color: #dee2e6 !important;
}

.form-label {
  line-height: 38px;
  margin-bottom: 0px !important;
}

.container-body.tabs {
  border-top-left-radius: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tabs {
  position: relative;
}

.nav-tabs { 
  margin: -15px -10px;
  border-bottom: none !important;
  border: none;
  cursor: pointer;
  z-index: 0 !important;
}

.nav-link {
  border: none !important;
}

.nav-link {
  padding-top: 20px;
  margin-top: -12px;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  padding-bottom: 15px;
  font-weight: bold;
}

.nav-link.active {
  border: none;
  border-bottom: 2px solid #009FE3 !important;
  color: #009FE3 !important;
}

.nav-link:hover {
  background-color: #f9f9f9;
}

.nav-link.disabled {
  color: #aaaaaa !important;
  text-decoration: none;
  cursor: not-allowed;
}

.tab-data {
  z-index: 1 !important;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  position: fixed;
  overflow: auto;
  top: 66px;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding-top: 10px;
  min-width: 250px;
  max-width: 250px;
  color: #1d1d1b;
  background: #ffffff;
  transition: all 0.3s;
  box-shadow: 1px 0 5px -3px #aaaaaa;
}

#sidebar .components {
  margin-bottom: 0px;
}

#sidebar .sidebar-title {
  padding: 30px 20px;
  margin-top: 0px;
  color: #888888;
  position: relative;
  text-transform: uppercase;
  font-size: 14px;
}

#sidebar .sidebar-title p {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#sidebar ul p {
  color: #ffffff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 12px 20px 12px 20px;
  font-size: 1.1em;
  display: block;
  font-weight: 300;
  line-height: 1.7em;
  color: #1d1d1b;
  border-radius: 10px;
  margin: 5px 15px;
}

#sidebar ul li a {
  text-decoration: none;
}

#sidebar ul li a i {
  width: 15px;
  margin-right: 15px;
}

.submenu {
  background-color: #fafafa;
  padding-top: 5px;
  padding-bottom: 5px;
}

.submenu a {
  padding-left: 50px !important;
  background: transparent;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #333333;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: underline;
  transition: all 0.3s;
}

.btn-link-table {
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbbbbb; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999999; 
}

.save-state {
  display: inline-block;
  padding-top: 10px;
}

.save-state-text {
  padding-left: 5px;
}

.col-no-padding {
  padding-right: 0px;
  padding-left: 0px;
}

.breadcrumb {
  color: #ffffff;
  background-color: transparent;
  position: fixed;
  top: 66px;
  height: 40px;
  align-items: center;
  padding: 0px 0px;
  border-radius: 0 !important;
  z-index: 42;
}

.breadcrumb-item.active {
  font-weight: bold;
  color: #ffffff;
}

.breadcrumb-item.active::before {
  color: #ffffff;
}

@media (max-width: 767px) {
  .breadcrumb {
    position: absolute;
  }
}

.btn {
   border-radius: .0rem;
}

.loading_overlay {
  position: fixed;
  z-index: 99;
  left: 250px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #f6f6f6;
}

.loading_overlay_fullscreen {
  left: 0px !important;
}

@media (max-width: 767px) {
  .loading_overlay {
    left: 0px !important;
  }
}

.loading_overlay_content {
  position: absolute;
  width: 150px;
  height: 130px;
  top: 50%;
  left: 50%;
  transform: translate(-75px, -65px)
}

.loading_overlay_content_loader {
  width: 150px;
}
.loading_overlay_content_loader > div {
  margin-left: 30px;
}

.loading_overlay_content_text{
  width: 150px;
  text-align: center;
  float: left;
  padding-top: 25px;
}

.content_loader {
  position: relative;
  width: 100%;
  padding-top: 5px;
  text-align: center;
}

.content_loader_spinner > div {
  margin: auto;
}

.content_loader_text {
  display: block;
  padding-top: 5px;
}

.warning-row {
  margin: 0px -20px 20px -20px;
}

.row-selectable {
  cursor: pointer !important;
}

.row-selectable:hover {
  background-color: #eeeeee !important;
}

tr:hover {
  background-color: transparent !important;
}

.action-allowed-row {
  font-weight: bold;
}

.numberCircle {
  display: inline-block;
  border-radius: 15px;
  min-width: 32px;
  height: 22px;
  padding-left: 3px !important;
  padding-right: 3px;
  background: #c8efff;
  text-align: center;
  margin-left: 10px;
  line-height: 22px;
  color: #009FE3; 
  font-size: 12px;
}

.sidebarIcon {
  margin-left: 12px;
}

.alertColor {
  color: #ff8800; 
}

.infoColor {
  color: #009FE3; 
}

.confirmColor {
  color: #00B64A; 
}

.MuiInputBase-root.Mui-disabled {
  background-color: #f6f6f6;
}

.MuiInputBase-input.Mui-disabled {
  color: #444444;
}

.async-button {
  display: inline-block;
}

.fullwidth-button {
  width: 100%;
}

.button-explanation {
  vertical-align: middle;
}

.button-icon-only {
  padding: 10px 0px !important;
  min-width: 40px !important;
} 

.button-icon-only > span > span {
  margin-left: 8px !important;
}

.button-inline {
  margin-top: 8px !important;
}

.end-off-input-button {
  position: absolute !important;
  right: -45px;
}

.MuiButton-containedSecondary {
  color: #ffffff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.MuiButton-containedSecondary:hover,
.MuiButton-containedSecondary:focus {
  background-color: #4b5157 !important;
  border-color: #4b5157 !important;
}

.MuiButton-root:disabled,
.MuiButton-root:hover:disabled,
.MuiButton-root:focus:disabled {
  cursor: default !important;
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.05) !important;;
  border-color: #a1a1a1 !important;
}

.MuiButton-root:disabled .MuiTouchRipple-root {
  display: none !important;
}

.button-loading {
  position: absolute;
  display: none !important;
}

.MuiButton-root:disabled .button-loading {
  display: block !important;
}

.MuiCircularProgress-colorPrimary {
  color: #252525 !important;
}

.MuiLink-underlineHover {
  text-decoration: underline !important;
}

.button-danger {
  background-color: #f52929 !important;
}

.button-danger:hover {
  background-color: #aa0000 !important;
}

.button-danger-outlined {
  color: #f52929 !important;
  border: 1px solid #f52929 !important;
}

.button-danger-outlined:hover {
  background-color: #f6e1e1 !important;
}

.list-row-item {
  padding: 20px 20px 10px 20px;
  margin: 20px 0px 20px 0px;
  background-color: #fafafa;
  border-radius: 5px;
}

.list-row-item-header-text {
  position: absolute;
  z-index: 1;
  color: rgba(0, 0, 0, 0.6);
  margin-top: -32px !important;
  margin-left: -10px;
  padding: 0px 5px;
}

.clear-list-row-item-button {
  position: absolute !important;
  top: -28px;
  right: -30px !important;
  z-index: 1;
}

.file-progress-bar {
  margin-top: 29px;
  margin-bottom: 25px;
}

.clear-form-button {
  position: absolute !important;
  right: -10px !important;
  z-index: 1;
}

.file-input-download-parent {
  position: relative;
}

.file-input-download-icon {
  position: absolute !important;
  left: 15px !important;
  top: 17px !important;
  overflow: hidden !important;
  white-space: nowrap;
  max-width: 80%;
  text-overflow: ellipsis;
}

.file-input-download-link {
  position: absolute !important;
  left: 35px !important;
  top: 20px !important;
  overflow: hidden !important;
  white-space: nowrap;
  max-width: 80%;
  text-overflow: ellipsis;
}

.download-document-button {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  text-transform: none !important;
}

.inline-download-document {
  display: inline-block;
  white-space: nowrap;
}

.multi-document-upload-field > div > div > input {
  color: transparent !important;
}

.multi-document-upload-field > div > div > input::after {
  content: "Upload multiple files";
  color: #000000;
  left: 122px;
  position: absolute;
  top: 14px;
}

.inline-download-document button .MuiButton-label {
  display: inline-block;
  overflow: hidden !important;
  max-width: 250px;
  text-overflow: ellipsis;
}

.download-document-button:hover {
  background-color: #e2e2e2 !important;
}

.multi-document-upload-field {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.multi-document-upload-title {
  position: absolute;
  z-index: 1;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  background-color: #fff;
  margin-top: -10px !important;
  margin-left: 9px;
  padding: 0px 5px;
}

.field-side-margins {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.field-no-margins {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.field-no-border > div > fieldset {
  border: none;
}

.signature-canvas {
  cursor: cell;
  cursor: url("../public/dot.cur"), auto;
  width: 100%;
  height: 150px !important;
  background-color: #f9f9f9;
  border-style: dashed;
  border-color: #aaaaaa;
  border-width: 1px;
}

.signature-canvas-clear {
  width: 100%;
  margin-top: -7px !important;
}

.signature-review {
  height: 100px !important;
}

.login-logo {
  padding: 20px;
}

.login-logo > img {
  width: 100%;
}

.pdf-viewer {
  height: 100%;
  width: 100%;
  min-height:90vh;
}

.flowBoxes {
  margin: 0px -23px !important;
  padding-top: 20px;
  padding-bottom: 0px;
  text-align: center;
}

.flowBoxes div {
  min-width: 110px;
  text-align: center;
  display:inline-block;
  position:relative;
  font-size: 14px;
  height: 23px;
  line-height: 22px;
  padding-left: 8px;
  background-color:white;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}

.flowBoxes-8 div {
  width: 12%;
}

.flowBoxes-7 div {
  width: 13.8%;
}

.flowBoxes div.first {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.flowBoxes div.last {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.flowBoxes div.left {
  margin-left: 0.5%;
}

.flowBoxes div.left:before{
  content:'';
  border-top:1px solid #f6f6f6;
  border-right:1px solid #f6f6f6;
  width:18px;
  height:18px;
  position:absolute;
  left:0;
  top:-1px;
  background-color:#f6f6f6;
  z-index: 1;
  margin-left: 1px;
  
  -webkit-transform: translate(-10px,4px) rotate(45deg);
     -moz-transform: translate(-10px,4px) rotate(45deg);
      -ms-transform: translate(-10px,4px) rotate(45deg);
       -o-transform: translate(-10px,4px) rotate(20deg);
          transform: translate(-10px,4px) rotate(45deg);
}

.flowBoxes div.right:after {
  content:'';
  border-top:1px solid #f6f6f6;
  border-right:1px solid #f6f6f6;
  width:17px;
  height:17px;
  position:absolute;
  right:0;
  top:-1px;
  background-color:white;
  z-index: 2;
  margin-right: 1px;
  
  -webkit-transform: translate(10px,4px) rotate(45deg);
     -moz-transform: translate(10px,4px) rotate(45deg);
      -ms-transform: translate(10px,4px) rotate(45deg);
       -o-transform: translate(10px,4px) rotate(20deg); 
          transform: translate(10px,4px) rotate(45deg);
}

.flowBoxes .active, .flowBoxes .passed {
  color:white;
}

.flowBoxes .passed > span > i.passed {
  display: contents !important;
}

.flowBoxes .skipped > span > i.passed {
  display: none !important;
}

.flowBoxes .skipped {
  color: #aaaaaa !important;
}

.flowBoxes .skipped,
.flowBoxes div.skipped:after {
  background-color:#eeeeee !important;
}

.flowBoxes .active,
.flowBoxes div.active:after {
  background-color:#004d6e;
}

.flowBoxes .passed,
.flowBoxes div.passed:after {
  background-color:#009FE3;
}

.link-button-wrapper, .link-button-wrapper:focus, .link-button-wrapper:hover {
  text-decoration: none;
}

.inline-link {
  font-size: 1rem !important;
  height: 19px !important;
  line-height: 0px !important;
  padding-bottom: 4px !important;
}

.icon-span {
  float: left;
  padding-top: 2px !important;
}

.requirements-list {
  list-style-type: none;
  padding-left: 15px;
  margin-bottom: 0px;
}

.requirements-list > li > .fa-times {
  color: red;
  width: 15px;
}

.requirements-list > li > .fa-check {
  color: green;
  width: 15px;
}

.table .thead-light th {
  background-color: transparent;
  border: none;
  color: #888888;
}

.header-text {
  color: #888888;
}

.form-header {
  margin: -5px -20px 10px -20px;
  padding: 0px 20px 15px 20px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
}

#sidebar ul li a:hover {
  background: #f1f1f1 !important;
}

#sidebar ul li.active>a {
  color: #009FE3;
  background: #e7f8ff;
}

#sidebar ul li:hover > a .numberCircle {
  color: #009FE3;
}

#sidebar ul li.active > a .numberCircle {
  color: #009FE3;
}

.MuiButton-containedPrimary {
  color: #ffffff !important;
  background-color: #009FE3 !important;;
  border-color: #009FE3 !important;
}

.MuiButton-containedPrimary:hover,
.MuiButton-containedPrimary:focus {
  background-color: #006f9e !important;;
  border-color: #006f9e !important;
}

.btn-link {
  color: #009FE3;
}

.btn-link:hover,
.btn-link:focus {
  color: #009FE3;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #009FE3 !important;
}

.MuiInputLabel-root.Mui-focused {
  color: #009FE3 !important;
}

.MuiButton-textPrimary {
  color: #009FE3 !important;
}

.MuiButton-outlinedPrimary {
  color: #009FE3 !important;
  border: 1px solid #009FE3 !important;
}

.MuiButton-outlinedSecondary {
  color: #555 !important;
  border: 1px solid #bbb !important;
}

.MuiButton-outlinedSecondary:hover,
.MuiButton-outlinedSecondary:focus,
.MuiButton-outlinedSecondary:active {
  background-color: #f7f7f7 !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #009FE3 !important;
}

.MuiLink-underlineHover {
  color: #009FE3 !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #009FE3 !important;
}

.MuiSlider-colorPrimary {
  color: #009FE3 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #009FE3 !important;
}

.page-title {
  padding-bottom: 0px;
}

.action-required-row {
  margin: 10px -35px -10px -35px;
}

.action-required-column {
  padding-top: 10px !important;
  width: 5px !important;
}

.no-spacing-bottom {
  padding-bottom: 0px;
}

.no-spacing-top {
  padding-top: 0px;
}

.right-corner {
  position: absolute;
  right: -30px;
  top: -17px;
}

.badge {
  color: #fff;
  background-color: #6c757d;
}

.badge-active {
  background-color: #009FE3 !important;
}

.badge-completed {
  background-color: #00B64A !important;
}

.badge-error {
  background-color: #b60000 !important;
}

.react-datepicker {
  position: absolute !important;
  z-index: 1;
  width: 219px;
}

.react-datepicker__navigation-icon {
  top: 5px !important;
}

.react-datepicker-button {
  width: 219px;
}

.loading-select-field-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.9;
}

.loading-text-field-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.9;
  padding: 6px 0px;
}

.loading-select-field-overlay > span > span {
  height: 100%;
  line-height: 24px;
  border-radius: 4px;
}

.loading-text-field-overlay > span > span {
  height: 100%;
  line-height: 20px;
  border-radius: 4px;
}

.loading-table {
  transition: 0.25s;
  overflow: hidden;
}

.col-fixed-icon-button {
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  width: 50px;
  margin-left: -5px;
  padding-right: 15px;
}

.negative-input > div > input {
  color: red !important;
}

.span-after-field {
  padding: 15px 0px !important;
  margin-left: -15px;
  color: #999999;
}
